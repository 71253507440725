import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

export const Register = (props) => {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [userCount, setUserCount] = useState("");

  React.useEffect(() => {
    (async () => {
      const rawReponse = await fetch("/api/user-count", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const json = await rawReponse.json();
      const userCount = json.totalCount;
      console.log(json);
      setUserCount(userCount);
    })();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    (async () => {
      const rawResponse = await fetch("/api/register", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstName, lastName, phoneNumber: phone, zip }),
      });
      const content = await rawResponse.json();

      if (content.success === true) {
        toast.success(content.msg);
        setfirstName("");
        setlastName("");
        setZip("");
        setPhone("");
      } else {
        toast.error(`Error ${content.msg}`);
      }
    })();
  };

  return (
    <div className="auth-form-container">
      <h1>SHABBOSTEXTS™️</h1>
      <p>
        <b>
          JOIN {userCount} PEOPLE IN GETTING SHABBOS START/END TIMES TEXTED
          EVERY FRIDAY MORNING!
        </b>
      </p>
      <br></br>
      <form className="register-form" onSubmit={handleSubmit}>
        <label htmlFor="firstName">First Name</label>
        <input
          minLength={3}
          maxLength={15}
          value={firstName}
          onChange={(e) => setfirstName(e.target.value)}
          type="firstName"
          placeholder="First Name"
          id="firstName"
          name="firstName"
        />
        <label htmlFor="lastName">Last Name</label>
        <input
          minLength={3}
          maxLength={15}
          value={lastName}
          onChange={(e) => setlastName(e.target.value)}
          type="lastName"
          placeholder="Last Name"
          id="lastName"
          name="lastName"
        />
        <label htmlFor="phone">Phone Number</label>
        <input
          minLength={10}
          maxLength={10}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          type="phone"
          placeholder="4242338990"
          id="phone"
          name="phone"
        />
        <label htmlFor="zip">Zipcode</label>
        <input
          minLength={5}
          maxLength={5}
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          type="zip"
          placeholder="90035"
          id="zip"
          name="zip"
        />
        <button type="submit">Register</button>
        <Toaster />
      </form>
      <button
        className="link-btn"
        onClick={() => props.onFormSwitch("unsubscribe")}
      >
        Want to Unsubscribe? Click here
      </button>
    </div>
  );
};
